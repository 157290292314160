import deepcopy from "deepcopy";
import { notificationsMixin } from "./notifications";
import apiSecured from "../api/secured";
import { localizationMixin } from "./localization";

export const editFormMixin = {
  mixins: [notificationsMixin, localizationMixin],
  data: () => ({
    closeAfterCreated: false,
    endpoint: null,
    getEndpoint: null,
    loading: false,
    originalRecord: {},
    emptyRecord: { id: null},
    record: {},
    show: false,
    changedProperties: [],
    errors: {}
  }),
  methods: {
    postProcess() {

    },
    prepare(prefill = null) {
      this.changedProperties = [];
      this.record = deepcopy(this.originalRecord);
      if (prefill !== null) {
        Object.keys(prefill).forEach(property => {
          this.record[property] = prefill[property];
          this.changedProperties.push(property);
        });
      }
      this.postProcess();
    },
    showCreate(prefill = null) {
      this.originalRecord = deepcopy(this.emptyRecord);
      this.prepare(prefill);
      this.show = true;
    },
    showEdit({ id }) {
      if (this.endpoint === null) {
        this.showErrorNotifications('Please provide an endpoint');
        return;
      }
      apiSecured.get(`${this.getEndpoint ? this.getEndpoint : this.endpoint}/${id}`).then((res) => {
        this.originalRecord = res.data;
        this.loading = false;
        this.showInfoNotification(this.__('common.data_loaded'));
        this.prepare();
        this.show = true;
      }).catch((error) => {
        this.originalRecord = {};
        this.prepare();
        this.loading = false;
        this.showErrorNotifications(error)
      });
    },
    registerChange(property) {
      if (!this.changedProperties.includes(property)) {
        this.changedProperties.push(property);
      }
    },
    isChanged(property) {
      return this.changedProperties.includes(property);
    },
    close() {
      this.show = false;
      this.$emit('closed')
    },
    validate() {
      let isValid = true;
      this.errors = {};
      this.changedProperties.forEach((changedProperty) => {
        if (changedProperty === 'name') {
          if (this.record[changedProperty].length < 1) {
            this.errors.name.push(this.__('validation.too_short').replace('$1', changedProperty).replace('$2', 1));
            isValid = false;
          }
        }
      });

      return isValid;
    },
    update() {
      if (this.endpoint === null) {
        this.showErrorNotifications('Please provide an endpoint');
        return;
      }

      if (!this.validate()) {
        return;
      }

      let payload = {};
      if (this.record.id !== null) {
        payload = [];
      }

      this.changedProperties.forEach((changedProperty) => {
        if (this.record.id !== null) {
          payload.push({
            op: 'replace',
            path: `/${changedProperty}`,
            value: this.record[changedProperty]
          });
        } else {
          payload[changedProperty] = this.record[changedProperty];
        }
      });

      this.loading = true;
      let method = apiSecured.post;
      let event = 'created';
      if (this.record.id !== null) {
        method = apiSecured.patch;
        event = 'updated';
      }
      method(this.endpoint + (this.record.id !== null ? '/' + this.record.id : ''), payload).then((res) => {
        this.loading = false;
        this.showSuccessNotification(this.__('common.saved'));
        this.record = res.data;
        this.originalRecord = deepcopy(this.record);
        this.prepare();
        this.changedProperties = [];
        this.$emit(event, this.record);
        if(event === 'created' && this.closeAfterCreated) {
          this.close();
        }
      }).catch((error) => {
        this.loading = false;
        this.showErrorNotifications(error);
      });
    }
    ,
  }
};