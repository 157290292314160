<template>
  <div class="file-tiles">
    <div class="file-tiles__tile"
         v-for="file in files"
         :class="fileClasses(file)"
         @click="handleClick(file)"
         v-slashes
         @mousedown.middle="openNewTab(file)"
    >
      <div class="file-tiles__tile-image" :style="{'background-image': `url(${renderFileImage(file)})`}">
      </div>
      <div class="file-tiles__tile-info">
        <div class="file-tiles__tile-title">
          <template v-if="file.name">{{ file.name }}</template>
          <template v-else>{{ file.filename }}</template>
        </div>
        <table class="file-tiles__tile-details">
          <tr v-if="file.type.toLowerCase() !== 'dir'">
            <td>Size:</td>
            <td>{{ fileSize(file.size) }}</td>
          </tr>
          <tr>
            <td>Owner:</td>
            <td>{{ file.owner ? file.owner.userName : file.ownerId}}</td>
          </tr>
          <tr>
            <td>Type:</td>
            <td>{{ file.type }}</td>
          </tr>
        </table>
        <div class="file-tiles__tile-details-button"
             :title="__('component.files.file_browser.show_file_actions_menu')"
             @click="toggleFileActions(file, $event)"
             v-slashes
        >
          <icon :icon="'context-menu'"/>
        </div>
      </div>
      <div class="file-tiles__tile-actions"
           @click="$event.stopPropagation()"
           @mouseleave="hideFileActions(file,$event,true)">
        <template v-for="recordAction in recordActions">
          <template v-if="recordAction.vif">
            <div
                v-if="recordAction.vif(file)"
                class="file-tiles__tile-action"
                v-slashes
                :title="recordAction.title"
                @click="emitActionEvent(file, recordAction, $event)"
            >
              <icon
                  :icon="recordAction.icon"
              />
            </div>
          </template>
          <template v-else-if="recordAction.restrictModAccess">
            <div
                v-if="hasModAccess(file)"
                class="file-tiles__tile-action"
                v-slashes
                :title="recordAction.title"
                @click="emitActionEvent(file, recordAction, $event)"
            >
              <icon
                  :icon="recordAction.icon"
              />
            </div>
          </template>
          <template v-else-if="recordAction.restrictRoles">
            <div
                v-if="hasOneRoles(recordAction.restrictRoles)"
                class="file-tiles__tile-action"
                v-slashes
                :title="recordAction.title"
                @click="emitActionEvent(file, recordAction, $event)"
            >
              <icon
                  :icon="recordAction.icon"
              />
            </div>
          </template>
          <div
              v-else
              class="file-tiles__tile-action"
              v-slashes
              :title="recordAction.title"
              @click="emitActionEvent(file, recordAction, $event)"
          >
            <icon
                :icon="recordAction.icon"
            />
          </div>
        </template>
        <div
            class="file-tiles__tile-action"
            v-slashes
            :title="__('component.files.file_browser.hide_file_actions_menu')"
            @click="hideFileActions(file,$event)"
        >
          <icon
              :icon="'context-menu'"
          />
        </div>
      </div>
      <div class="file-tiles__nda-hint" v-if="isNDAprotected(file)">NDA!</div>
    </div>
  </div>
</template>

<script>
  import filesize from 'filesize';
  import {userMixin} from '../../../mixins/user';
  import { localizationMixin } from "../../../mixins/localization";

  export default {
    mixins: [userMixin, localizationMixin],
    props: {
      files: {
        type: Array,
        default: () => []
      },
      recordActions: {
        type: Array,
        default: () => []
      },
      selected: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      showFileActionsFor: []
    }),
    methods: {
      openNewTab(file) {
        const result = this.$router.resolve({ name: 'file_browser', query: { parentId: file.id}});
        window.open(result.href, '_blank',);
      },
      isNDAprotected(file) {
        return file.viewAccessesRoleNames && file.viewAccessesRoleNames.includes('NDA Access');
      },
      fileClasses(file) {
        return {
          'file-tiles__tile--with-access': file.viewAccesses && file.viewAccesses.length > 0,
          'file-tiles__tile--with-mod-access': file.modAccesses && file.modAccesses.length > 0,
          'file-tiles__tile--actions-visible': this.actionsShown(file),
          'file-tiles__tile--selected': this.selected.some(selected => selected.id === file.id)
        }
      },
      actionsShown(file) {
        return this.showFileActionsFor.includes(file.id);
      },
      toggleFileActions(file, event) {
        event.stopPropagation();
        if (this.showFileActionsFor.includes(file.id)) {
          this.hideFileActions(file, event)
        } else {
          this.showFileActionsFor.push(file.id);
        }
      },
      hideFileActions(file, event) {
        event.stopPropagation();
        if (this.showFileActionsFor.includes(file.id)) {
          this.showFileActionsFor = this.showFileActionsFor.filter(fileId => fileId !== file.id);
        }
      },
      renderFileImage(file) {
        if (file.type.toLowerCase() === 'png' || file.type.toLowerCase() === 'gif' || file.type.toLowerCase() === 'jpg' || file.type.toLowerCase() === 'mp4') {
          return file.thumbnailUri;
        } else if (file.type.toLowerCase() === 'svg') {
          return file.uri;
        } else if (file.type.toLowerCase() === 'dir') {
          return process.env.VUE_APP_CDN_PUBLIC_BASE_PATH + '/ui/svgs/folder.svg';
        }
        return process.env.VUE_APP_CDN_PUBLIC_BASE_PATH + '/ui/svgs/file_blank.svg';
      },
      handleClick(file) {
        if (file.type.toLowerCase() === 'dir') {
          this.$emit('navigate', file);
        } else {
          this.$emit('view', file);
        }
      },
      fileSize(bytes) {
        return filesize(bytes);
      },
      emitActionEvent(record, recordAction, event) {
        event.stopPropagation();
        this.$emit(recordAction.event, record);
      },
    }
  }
</script>

<style scoped>

</style>