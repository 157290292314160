<template>
  <div class="edit-file">
    <my-dialog :show="show" @close="close" max-width="400px">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('component.files.create_dir.title')}}</div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content" v-if="file">
          <div class="layout layout--wrap">
            <div class="flex xs12 mt">
              <text-field
                  v-model="file.filename"
                  :label="__('entity.fal.file.filename')"
                  :error-messages="errors.filename"
                  maxlength="30"
                  :prepend-icon="isChanged('filename') ? 'pencil' : null"
                  @input="registerChange('filename')"
              />
            </div>
            <div class="flex xs6 mt">
              <div class="btn btn--success" :loading="loading" :disabled="changedProperties.length === 0" @click="createDirectory" v-slashes>{{ __('common.create') }}</div>
            </div>
            <div class="flex xs6 mt">
              <div class="btn btn--primary" @click="close" v-slashes>{{ __('common.close') }}</div>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import apiSecured from '../../api/secured';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';

  export default {
    mixins: [notificationsMixin, localizationMixin],
    props: ['currentDir'],
    data: () => ({
      loading: false,
      file: { id: null, filename: '' },
      show: false,
      changedProperties: [],
      errors: {
        filename: []
      },
      roles: []
    }),
    methods: {
      showCreate() {
        this.file = { id: 0, filename: '' };
        this.show = true;
      },
      registerChange(property) {
        if (!this.changedProperties.includes(property)) {
          this.changedProperties.push(property);
        }
      },
      isChanged(property) {
        return this.changedProperties.includes(property);
      },
      close() {
        this.show = false;
        this.$emit('closed')
      },
      createDirectory() {
        let payload = {};
        let isValid = true;
        this.errors = {
          name: []
        };
        this.changedProperties.forEach((changedProperty) => {
          payload[changedProperty] = this.file[changedProperty];
          if (changedProperty === 'filename') {
              if(payload[changedProperty].length < 1) {
                this.errors.filename.push(this.__('validation.too_short').replace('$1',changedProperty).replace('$2',1));
                isValid = false;
              }
          }
        });

        if (!isValid) {
          return;
        }
        payload.parentId = this.currentDir ? this.currentDir.id : null;

        this.loading = true;
        let endpoint = '/fal/file/directory';
        let event = 'directoryCreated';
        apiSecured.post(endpoint, payload).then((res) => {
          this.loading = false;
          this.showSuccessNotification(res.data.message);
          this.file = res.data;
          this.changedProperties = [];
          this.$emit(event, this.file);
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      }
    },
  }
</script>