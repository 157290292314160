export const rerenderMixin = {
  methods: {
    /**
     * Note that you have to add a property rerenderKey: 'no-set' to the data object.
     */
    forceRerender() {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < 32; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      this.rerenderKey = result;
    }
  }
};