<template>
  <div class="file-browser view">
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}" :key="currentDir ? currentDir.id : 'null'">
      <div class="control-bar__breadcrumb">
        <div class="control-bar__breadcrumb-item"
             @click="navigate({id: null})"
        >
          <img class="control-bar__breadcrumb-item-icon"
               src="https://cdn.hyperion-corporation.de/ui/svgs/chevron-right.svg" alt="chevron-left"
          />
          <span>root</span>
        </div>
        <div class="control-bar__breadcrumb-item" v-for="breadcrumbItem in breadcrumbItems"
             @click="navigate(breadcrumbItem)"
        >
          <img class="control-bar__breadcrumb-item-icon"
               src="https://cdn.hyperion-corporation.de/ui/svgs/chevron-right.svg" alt="chevron-left"
          />
          <span>{{ breadcrumbItem.name ? breadcrumbItem.name : breadcrumbItem.filename }}</span>
          <span class="control-bar__breadcrumb-nda-hint" v-if="isNDAprotected(breadcrumbItem)">NDA!</span>
        </div>
      </div>
      <text-field
          class="control-bar__search"
          v-model="searchTerm"
          :label="__('common.search_term')"
          max-length="50"
          :hide-details="true"
          :clearable="true"
          @keyup:enter="refreshDelayed(100)"
          @click:clear="refreshDelayed(100)"
      />
      <div class="control-bar__buttons">
        <div class="control-bar__button"
             :title="__('common.refresh')"
             @click="refresh"
             v-slashes
        >
          <img class="control-bar__button-icon"
               src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"
          />
        </div>
        <div class="control-bar__button"
             :title="__('component.files.file_browser.navigate_up')"
             @click="navigateUp"
             v-slashes v-if="parentId !== null"
        >
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/chevron-left.svg"
               alt="back"/>
        </div>
        <div class="control-bar__button"
             :title="__('component.files.file_browser.switch_to_tiles')"
             @click="displayMode = 'tiles'"
             v-slashes
             v-if="displayMode !== 'tiles'"
        >
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/tiles.svg"
               alt="tiles"/>
        </div>
        <div class="control-bar__button"
             :title="__('component.files.file_browser.switch_to_list')"
             @click="displayMode = 'list'"
             v-slashes
             v-if="displayMode !== 'list'"
        >
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/list.svg"
               alt="list"/>
        </div>
        <div class="control-bar__button"
             :title="__('component.files.file_browser.show_gallery_mode')"
             @click="showSwiper()"
             v-slashes
        >
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/file_video.svg"
               alt="gallery"/>
        </div>
        <div class="control-bar__button"
             :title="__('component.files.file_browser.upload_files')"
             @click="showUpload"
             v-slashes
             v-if="hasModAccess(this.currentDir)"
        >
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/upload.svg"
               alt="upload"/>
        </div>
        <div class="control-bar__button"
             :title="__('component.files.file_browser.create_dir')"
             @click="showCreateDir"
             v-slashes
             v-if="hasModAccess(this.currentDir)"
        >
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/add.svg"
               alt="create"/>
        </div>
        <div class="control-bar__button"
             :title="__('component.files.file_browser.apply')"
             @click="handleMultipleSelected"
             v-slashes
             v-if="embedded && multiple"
        >
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/check.svg"
               alt="create"/>
        </div>
      </div>
    </div>
    <pagination
        class="mt"
        :count-pages="pagination.pages"
        :total-visible="10"
        :page="pagination.page"
        :disabled="loading"
        @paginate="paginate"
    />
    <data-table
        v-if="displayMode === 'list'"
        :records="files"
        :fields="dataTableFields"
        :record-actions="recordActions"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :loading="loading"
        @editFile="editFile"
        @deleteFile="deleteFile"
        @sort="sort"
        @navigate="navigate"
        @view="showSwiper"
        @open="openFile"
        @copyUri="copyUri"
    />
    <file-tiles
        v-if="displayMode === 'tiles'"
        :files="files"
        :record-actions="recordActions"
        :selected="selectedFiles"
        class="mt"
        @editFile="editFile"
        @deleteFile="deleteFile"
        @sort="sort"
        @navigate="navigate"
        @view="showSwiper"
        @open="openFile"
        @copyUri="copyUri"
    />
    <pagination
        class="mt"
        :count-pages="pagination.pages"
        :total-visible="10"
        :page="pagination.page"
        :disabled="loading"
        @paginate="paginate"
    />
    <edit-file ref="editFile" @updated="refresh"/>
    <create-dir ref="createDirectory" :current-dir="currentDir" @directoryCreated="handleDirCreated"/>
    <upload-files ref="uploadFiles" :current-dir="currentDir" @filesUploaded="refresh" @closed="refresh"/>
    <swiper ref="swiper" :current-dir="currentDir" :files="files"/>
    <input id="copy_uri" :value="uriToCopy" readonly style="opacity: 0"/>
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  import apiSecured from '../../api/secured';
  import DataTable from '../../components/DataTable';
  import FileTiles from '../../components/Files/FileBrowser/FileTiles'
  import EditFile from '../../components/Files/EditFile';
  import UploadFiles from '../../components/Files/UploadFiles';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { userMixin } from "../../mixins/user";
  import CreateDir from "./CreateDir";
  import Swiper from "./Swiper";
  import { pageTitleMixin } from "../../mixins/pageTitle";
  import { paginationMixin } from "../../mixins/pagination";

  function renderFileImage(record) {
    if (record.type.toLowerCase() === 'png' || record.type.toLowerCase() === 'gif' || record.type.toLowerCase() === 'jpg' || record.type.toLowerCase() === 'mp4') {
      return record.thumbnailUri;
    } else if (record.type.toLowerCase() === 'svg') {
      return record.uri;
    } else if (record.type.toLowerCase() === 'dir') {
      return process.env.VUE_APP_CDN_PUBLIC_BASE_PATH + '/ui/svgs/folder.svg';
    }
    return process.env.VUE_APP_CDN_PUBLIC_BASE_PATH + '/ui/svgs/file_blank.svg';
  }

  export default {
    mixins: [notificationsMixin, userMixin, localizationMixin, pageTitleMixin, paginationMixin],
    components: {
      Swiper,
      CreateDir,
      DataTable,
      FileTiles,
      EditFile,
      UploadFiles
    },
    props: {
      embedded: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      },
      preselected: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      loading: false,
      files: [],
      currentDir: null,
      userstorage: null,
      sortBy: 'filename',
      sortDesc: false,
      displayMode: 'tiles',
      dataTableFields: [],
      recordActions: [],
      searchTerm: '',
      parentId: null,
      selectedFiles: [],
      uriToCopy: '',
      skipCreated: true,
    }),
    watch: {
      '$route'(to, from) {
        // if (to.name === this.$route.name) {
        //   if (this.$route.params.currentDirPath) {
        //     this.navigateWithPath((this.path = '/' + decodeURIComponent(this.$route.params.currentDirPath)).replace('//', '/'));
        //   } else {
        //     this.navigateWithPath('/');
        //   }
        // }
      },
      preselected() {
        this.selectedFiles = this.preselected;
      }
    },
    computed: {
      breadcrumbItems() {
        let breadcrumbItems = [];
        if (this.currentDir !== null) {
          breadcrumbItems = this.resolveBreadcrumbItems(this.currentDir, []).reverse();
        }
        return breadcrumbItems;
      },
    },
    methods: {
      copyUri({uri}) {
        this.uriToCopy = uri;
        this.$nextTick()
          .then(() => {
            let copyText = document.getElementById("copy_uri");
            copyText.select();
            document.execCommand("copy");
            this.showSuccessNotification(this.__('uriCopied'));
          });
      },
      refreshDelayed(delay) {
        setTimeout(this.refresh, delay);
      },
      resolveBreadcrumbItems(file, items) {
        items.push(file);
        if (file.parent !== null) {
          items = this.resolveBreadcrumbItems(file.parent, items);
        }
        return items;
      },
      isNDAprotected(file) {
        return file.viewAccessesRoleNames && file.viewAccessesRoleNames.includes('NDA Access');
      },
      async refresh() {
        this.loading = true;
        const params = this.getPaginationParams();
        params.parentId = this.parentId;
        await apiSecured.get('/fal/file', { params }).then(async (res) => {
          this.files = await this.enrichRecords(res.data);
          this.updatePagination(res);
          this.loading = false;
          this.showInfoNotification('Files have been reloaded');
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
          this.navigate(this.userstorage);
        });
      },
      async enrichRecords(files) {
        const ownerIds = [...(new Set(files.filter(record => record.ownerId !== null).map(record => record.ownerId)))];
        if (ownerIds.length > 0) {
          await apiSecured.get(`/community/user/(${ownerIds.join(',')})`, {
            params: {
              fields: 'id,userName'
            },
          }).then((res) => {
            files = files.map(file => {
              file.owner = res.data.find(resRecord => resRecord.id === file.ownerId);
              return file;
            });
            this.showInfoNotification('Owners have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }

        files = files.map(file => {
          file.viewAccessesRoleNames = [];
          file.modAccessesRoleNames = [];
          file.viewAccessesRoleNames = file.viewAccesses.map(viewAccess => viewAccess.role.name);
          file.modAccessesRoleNames = file.modAccesses.map(modAccess => modAccess.role.name);
          return file;
        })

        return files;
      },
      async refreshUserStorage() {
        this.loading = true;
        await apiSecured.get('/fal/file/userstorage').then((res) => {
          this.userstorage = res.data;
          this.loading = false;
          this.showInfoNotification('Userstorage has been reloaded');
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
      async refreshCurrentDir() {
        if (this.parentId === null) {
          this.currentDir = null;
          return;
        }
        this.loading = true;
        await apiSecured.get('/fal/file/' + this.parentId).then(async (res) => {
          this.currentDir = await this.enrichRecordRecursive(res.data);
          this.loading = false;
          this.showInfoNotification('currentDir has been reloaded');
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
      async enrichRecordRecursive(file) {
        file.viewAccessesRoleNames = [];
        file.modAccessesRoleNames = [];
        file.viewAccessesRoleNames = file.viewAccesses.map(viewAccess => viewAccess.role.name);
        file.modAccessesRoleNames = file.modAccesses.map(modAccess => modAccess.role.name);
        if(file.parent) {
          file.parent = await this.enrichRecordRecursive(file.parent);
        }
        return file;
      },
      sort({ sortBy, sortDesc }) {
        this.sortBy = sortBy;
        this.sortDesc = sortDesc;
        this.refresh();
      },
      navigateUp() {
        if (this.currentDir !== null) {
          this.navigate(this.currentDir.parent);
        }
      },
      async navigate(file) {
        this.parentId = file.id;
        this.searchTerm = '';
        await this.refreshCurrentDir();
        await this.refresh();
        if (!this.embedded) {
          if(this.$route.query.parentId !== this.parentId) {
            this.$router.push({ name: 'file_browser', query: { parentId: this.parentId } }).catch(err => {
            });
          }
          this.setPageTitle(`${this.currentDir.name ? this.currentDir.name : this.currentDir.filename} - File Browser`);
        }
      },
      openFile(file) {
        window.open(file.uri);
      },
      showUpload() {
        this.$refs.uploadFiles.showDialog();
      },
      editFile(file) {
        this.$refs.editFile.showEdit(file);
      },
      async showCreateDir() {
        await this.refresh();
        this.$refs.createDirectory.showCreate();
      },
      deleteFile(file) {
        Swal.fire({
          title: 'Are you sure?',
          text: "Do you really want to permanently delete this file from the server?",
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            this.loading = true;
            let endpoint = '/fal/file/' + file.id;
            apiSecured.delete(endpoint).then((res) => {
              this.loading = false;
              this.showSuccessNotification(this.__('common.deleted'));
              this.refresh();
            }).catch((error) => {
              this.loading = false;
              this.showErrorNotifications(error);
              this.refresh();
            });
          }
        });
      },
      handleDirCreated(file) {
        this.$refs.createDirectory.close();
        this.refresh();
        this.editFile(file);
      },
      showSwiper(file = null) {
        if (this.embedded && file !== null) {
          if (this.multiple) {
            this.toggleSelection(file);
          } else {
            this.$emit('fileSelected', file);
          }
        } else {
          this.$refs.swiper.showDialog(file);
        }
      },
      toggleSelection(file) {
        const index = this.selectedFiles.findIndex(selectedFile => selectedFile.id === file);
        if (index > -1) {
          this.selectedFiles.splice(index, 1);
        } else {
          if (['jpg', 'png', 'gif', 'svg', 'mp4'].includes(file.type.toLowerCase())) {
            this.selectedFiles.push(file);
          }
        }
      },
      handleMultipleSelected() {
        this.$emit('multipleFilesSelected', this.selectedFiles);
      }
    },
    async created() {
      this.selectedFiles = this.preselected;
      this.dataTableFields = [
        { name: "icon", title: this.__('entity.common.icon'), type: "image-rendered", render: renderFileImage },
        { name: "id", title: this.__('entity.common.id'), type: "default" },
        { name: "filename", title: this.__('entity.fal.file.filename'), type: "default" },
        { name: "name", title: this.__('entity.common.name'), type: "default" },
        { name: "type", title: this.__('entity.common.type'), type: "default" },
        { name: "size", title: this.__('entity.common.size'), type: "filesize" },
        { name: "viewAccessesRoleNames", title: this.__('entity.fal.file.view_access'), type: "array", disableSorting: true },
        { name: "modAccessesRoleNames", title: this.__('entity.fal.file.mod_access'), type: "array", disableSorting: true },
        { name: "owner.userName", title: this.__('entity.fal.file.owner'), type: "ref", disableSorting: true },
        { name: "createdAt", title: this.__('entity.common.created_at'), type: "datetime" },
        { name: "updatedAt", title: this.__('entity.common.updated_at'), type: "datetime" }
      ];
      this.recordActions = [
        {
          title: this.__('component.files.file_browser.open_folder'),
          icon: 'folder-open',
          event: 'navigate',
          vif: (record) => {
            return record.type.toLowerCase() === 'dir'
          }
        },
        {
          title: this.__('component.files.file_browser.view_file'), icon: 'eye', event: 'view', vif: (record) => {
            return ['jpg', 'gif', 'png', 'mp4', 'svg'].includes(record.type.toLowerCase());
          }
        },
        {
          title: this.__('copyUri'),
          icon: 'copy',
          event: 'copyUri',
          vif: (record) => {
            return record.type.toLowerCase() !== 'dir'
          }
        },
        {
          title: this.__('component.files.file_browser.open_file'),
          icon: 'open-in-new',
          event: 'open',
          vif: (record) => {
            return record.type.toLowerCase() !== 'dir'
          }
        },
        {
          title: this.__('component.files.file_browser.edit_file'),
          icon: 'pencil',
          event: 'editFile',
          restrictModAccess: true
        },
        {
          title: this.__('component.files.file_browser.delete_file'), icon: 'bin', event: 'deleteFile',
          restrictModAccess: true
        },
      ];
      this.parentId = null;
      await this.refreshUserStorage();
      if (this.$route.query.parentId && !this.embedded) {
        this.navigate({ id: this.$route.query.parentId });
      } else {
        await this.refreshUserStorage();
        this.navigate({ id: this.userstorage.id });
      }
    },
    beforeRouteUpdate (to, from, next) {
      if (to.query.parentId !== this.parentId && !this.embedded) {
        this.navigate({ id: to.query.parentId });
      }
      next();
    }
  }
</script>
