<template>
  <div class="select-avatar">
    <div class="select-avatar__avatar"
         :style="{'background-image': `url(${fullAvatarPath})`}"
         @click="showFileBrowser()"
    ></div>
    <my-dialog :show="fileBrowserShown" :fullscreen="true">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('component.auth.select_avatar.title')}}</div>
          <div class="card__button" @click="hideFileBrowser()">
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content">
          <file-browser :embedded="true" @fileSelected="handleFileSelected"/>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import FileBrowser from '../../components/Files/FileBrowser';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';

  export default {
    components: { FileBrowser },
    mixins: [notificationsMixin, localizationMixin],
    props: ['changed', 'originalAvatar'],
    data: () => ({
      avatar: null,
      fileBrowserShown: false
    }),
    watch: {
      originalAvatar() {
        this.avatar = this.originalAvatar;
      }
    },
    computed: {
      fullAvatarPath() {
        return CSS.escape(this.avatar);
      }
    },
    methods: {
      showFileBrowser() {
        this.fileBrowserShown = true;
      },
      hideFileBrowser() {
        this.fileBrowserShown = false;
      },
      handleFileSelected(file) {
        if (file.type.toLowerCase() === 'jpg' || file.type.toLowerCase() === 'png' || file.type.toLowerCase() === 'gif') {
          this.avatar = process.env.VUE_APP_CDN_PUBLIC_BASE_PATH + file.fullRelativePath;
          this.fileBrowserShown = false;
          this.$emit('avatarSelected', this.avatar);
        } else {
          this.showErrorNotifications(this.__('component.auth.select_avatar.filetype_not_supported'));
        }
      }
    },
    created() {
      this.avatar = this.originalAvatar;
    }
  }
</script>