import duration from "format-duration";

export const numberToLocaleString = (value, minimumFractionDigits = 3, maximumFractionDigits = 3) => {
  if ([undefined, null].includes(value)) {
    return null;
  }
  const raw = value.toLocaleString(undefined, { minimumFractionDigits, maximumFractionDigits });
  if(minimumFractionDigits > 0) {
    const precision = raw.substr(-(minimumFractionDigits+1));
    return raw.replace(precision, `<span class="text--disabled">${precision}</span>`)
  }
  return raw;
};

export const renderWithTierAndSize = (name, scale, tier) => {
  const tieredName = name ? name
    .replace('basic ', '<span class="text--tier-1">Basic</span> ')
    .replace('Basic ', '<span class="text--tier-1">Basic</span> ')
    .replace('Uncommon ', '<span class="text--tier-2">Uncommon</span> ')
    .replace('Advanced ', '<span class="text--tier-3">Advanced</span> ')
    .replace('Rare ', '<span class="text--tier-4">Rare</span> ')
    .replace('Exotic ', '<span class="text--tier-5">Exotic</span> ')
    : '';

  if (scale && tier) {
    return `<span class="text-shadow--tier-${tier}">${tieredName} <span class="text--primary">${scale.toUpperCase()}</span></span>`;
  }
  if (scale) {
    return `${tieredName} <span class="text--primary">${scale.toUpperCase()}</span>`;
  }
  if (tier) {
    return `<span class="text-shadow--tier-${tier}">${tieredName}</span>`;
  }
  return tieredName;
};

export const displayTime = (seconds) => {
  return duration(seconds * 1000);
};