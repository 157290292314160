
export const renderDatetime = (value) => {
  if (value !== null) {
    let datetime = new Date(value);
    const datestring = `${datetime.getFullYear()}-${(datetime.getMonth() + 1).toString().padStart(2, '0')}-${datetime.getDate().toString().padStart(2, '0')}`;
    const timestring = `${datetime.getHours().toString().padStart(2, '0')}:${datetime.getMinutes().toString().padStart(2, '0')}:${datetime.getSeconds().toString().padStart(2, '0')}`;
    const fDatestring = `<span class="text--teal">${datestring}</span>`;
    const fTimestring = `<span class="text--cyan">${timestring}</span>`;
    return `${fDatestring} ${fTimestring}`;
  }
  return '-';
}
