<template>
  <div class="data-table">
    <div class="data-table__row">
      <div
          v-for="field in fields" v-slashes
          class="data-table__cell data-table__cell--header"
          :class="{'text-xs-right': field.type === 'numeric', 'data-table__cell--no-sorting': field.disableSorting}"
          @click="emitSorting(field)"
      >
        {{ field.title }}
        <img
            v-if="sortBy === field.name && !field.disableSorting"
            class="data-table__sort-icon"
            :class="{'data-table__sort-icon--inverted': sortDesc}"
            src="https://cdn.hyperion-corporation.de/ui/svgs/triangle-up.svg" alt="^"
        />
      </div>
      <div class="data-table__cell data-table__cell--header data-table__cell--no-sorting">
      </div>
    </div>
    <div v-for="record in records" class="data-table__row">
      <div v-for="field in fields" class="data-table__cell"
           :class="{'text-xs-right': field.type === 'numeric' || field.type === 'filesize', 'data-table__cell--image': field.type === 'image' || field.type === 'image-rendered' || field.type === 'srcOrSvg'}">
        <template v-if="field.type === 'default'">
          {{ record[field.name] }}
        </template>
        <template v-if="field.type === 'numeric' && record[field.name] !== null">
          <span v-html="numberToLocaleString(record[field.name])"></span>
        </template>
        <template v-if="field.type === 'ref' && record[refProp(field.name)]">
          {{ record[refProp(field.name)][refField(field.name)] }}
        </template>
        <template v-if="field.type === 'arr-ref' && record[refProp(field.name)]">
          {{ record[refProp(field.name)][refField(field.name)].join(', ') }}
        </template>
        <template v-if="field.type === 'sub-ref' && record[refProp(field.name)]">
          {{ getSubRef(record, field.name) }}
        </template>
        <template v-if="field.type === 'boolean'">
          <div class="ribbon success" v-if="record[field.name]">yes</div>
          <div class="ribbon warning" v-else>no</div>
        </template>
        <template v-if="field.type === 'boolean-inverted'">
          <div class="ribbon warning" v-if="record[field.name]">yes</div>
          <div class="ribbon success" v-else>no</div>
        </template>
        <template v-if="field.type === 'boolean-negated'">
          <div class="ribbon success" v-if="!record[field.name]">yes</div>
          <div class="ribbon warning" v-else>no</div>
        </template>
        <template v-if="field.type === 'boolean-text'">
          <div class="text--success text--orbitron" v-if="record[field.name]">yes</div>
          <div class="text--warning text--orbitron" v-else>no</div>
        </template>
        <template v-if="field.type === 'array' && record[field.name] !== undefined">
          {{ record[field.name].join(', ') }}
        </template>
        <template v-if="field.type === 'enum' && record[field.name] !== undefined">
          {{ field.values[field.name] }}
        </template>
        <template v-if="field.type === 'image'">
          <img :src="record[field.name]" alt="#"/>
        </template>
        <template v-if="field.type === 'image-rendered'">
          <img :src="field.render(record)" alt="#"/>
        </template>
        <template v-if="field.type === 'srcOrSvg'">
          <img v-if="isNqAsset(record[field.name])"
               :src="`https://${record[field.name]}`" alt="-"
          />
          <img v-else-if="isDataImage(record[field.name])"
               :src="`${getDataImageCleaned(record[field.name])}`" alt="-"
          />
          <img v-else-if="isUrl(record[field.name])"
               :src="record[field.name]" alt="-"
          />
          <div v-else v-html="record[field.name]"></div>
        </template>
        <template v-if="field.type === 'datetime'">
          <span v-html="renderDatetime(record[field.name])"></span>
        </template>
        <template v-if="field.type === 'filesize'">
          <span v-html="fileSize(record[field.name])"></span>
        </template>
        <template v-if="field.type === 'vec3'">
          <span v-html="renderVec3(record[field.name])"></span>
        </template>
        <template v-if="field.type === 'custom'">
          <span v-html="field.render(record[field.name])"></span>
        </template>
      </div>
      <div class="data-table__cell data-table__cell--actions">
        <div class="data-table__actions-wrapper">
          <template v-for="recordAction in recordActions">
            <template v-if="recordAction.vif">
              <div
                  v-if="recordAction.vif(record)"
                  class="data-table__action"
                  v-slashes
                  :title="recordAction.title"
                  @click="emitActionEvent(record, recordAction)"
              >
                <icon
                    :icon="recordAction.icon"
                />
              </div>
            </template>
            <template v-else-if="recordAction.restrictModAccess">
              <div
                  v-if="hasModAccess(record)"
                  class="data-table__action"
                  v-slashes
                  :title="recordAction.title"
                  @click="emitActionEvent(record, recordAction)"
              >
                <icon
                    :icon="recordAction.icon"
                />
              </div>
            </template>
            <template v-else-if="recordAction.restrictRoles">
              <div
                  v-if="hasOneRoles(recordAction.restrictRoles)"
                  class="data-table__action"
                  v-slashes
                  :title="recordAction.title"
                  @click="emitActionEvent(record, recordAction)"
              >
                <icon
                    :icon="recordAction.icon"
                />
              </div>
            </template>
            <div
                v-else
                class="data-table__action"
                v-slashes
                :title="recordAction.title"
                @click="emitActionEvent(record, recordAction)"
            >
              <icon
                  :icon="recordAction.icon"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import filesize from 'filesize';
  import { userMixin } from '@/mixins/user';
  import { renderDatetime } from '@/helpers/generalHelpers';
  import { numberToLocaleString } from "@/helpers/itemHelpers";

  const NQ_ASSETS_BASE_URL = 'assets.prod.novaquark.com';
  const DATA_IMAGE = 'data:image';

  export default {
    mixins: [userMixin],
    props: ['records', 'fields', 'recordActions', 'loading', 'sortBy', 'sortDesc'],
    data: () => ({}),
    methods: {
      isNqAsset(value) {
        return value && value.substr(0,NQ_ASSETS_BASE_URL.length).toLowerCase() === NQ_ASSETS_BASE_URL;
      },
      isDataImage(value) {
        return value && value.substr(1,DATA_IMAGE.length).toLocaleLowerCase() === DATA_IMAGE;
      },
      isUrl(value) {
        return value && value.substr(0,5).toLocaleLowerCase() === 'https';
      },
      getDataImageCleaned(value) {
        return value.substr(1, value.length - 2);
      },
      refProp(fieldName) {
        return fieldName.split('.')[0];
      },
      refSubProp(fieldName) {
        return fieldName.split('.')[1];
      },
      refField(fieldName) {
        return fieldName.split('.')[1];
      },
      refSubField(fieldName) {
        return fieldName.split('.')[2];
      },
      getSubRef(record, fieldName) {
        const parts = fieldName.split('.');
        const prop = parts[0];
        const subProp = parts[1];
        const field = parts[2];
        if (record.hasOwnProperty(prop)) {
          if (record[prop].hasOwnProperty(subProp)) {
            if (record[prop][subProp].hasOwnProperty(field)) {
              return record[prop][subProp][field];
            }
          }
        }
        return '';
      },
      renderDatetime,
      numberToLocaleString,
      renderVec3(value) {
        const x = `<span class="text--teal">${value && value.x ? value.x.toLocaleString() : '-'}</span>`;
        const y = `<span class="text--cyan">${value && value.y ? value.y.toLocaleString() : '-'}</span>`;
        const z = `<span class="text--lime">${value && value.z ? value.z.toLocaleString() : '-'}</span>`;
        return `x:&nbsp;${x}<br/>y:&nbsp;${y}<br/>z:&nbsp;${z}`;
      },
      emitActionEvent(record, recordAction) {
        if (!this.loading) {
          this.$emit(recordAction.event, record);
        }
      },
      emitSorting(field) {
        if(field.disableSorting) {
          return;
        }

        let newDesc = this.sortDesc;
        if (field.name === this.sortBy) {
          newDesc = !this.sortDesc;
        }
        this.$emit('sort', { sortBy: field.name, sortDesc: newDesc });
      },
      fileSize(bytes) {
        return filesize(bytes);
      }
    },
    created() {
    }
  }
</script>
