<template>
  <div class="edit-file">
    <my-dialog :show="show" @close="close">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('component.files.edit_file.title')}}</div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content" v-if="originalRecord.id !== null">
          <progress-linear v-if="loading"/>
          <div class="layout layout--wrap">
            <div class="flex xs12 sm6">
              <table class="object-table" v-if="originalRecord">
                <tr>
                  <td>{{__('entity.common.id')}}:</td>
                  <td>{{ originalRecord.id}}</td>
                </tr>
                <tr>
                  <td>{{__('entity.common.name')}}:</td>
                  <td>{{ originalRecord.name }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.common.description')}}:</td>
                  <td>{{ originalRecord.description }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.fal.file.filename')}}:</td>
                  <td>{{ originalRecord.filename }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.fal.file.path')}}:</td>
                  <td>{{ originalRecord.fullRelativePath }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.common.type')}}:</td>
                  <td>{{ originalRecord.type }}</td>
                </tr>
                <tr v-if="originalRecord.type !== 'dir'">
                  <td>{{__('entity.common.size')}}:</td>
                  <td>{{ originalRecord.size }}</td>
                </tr>
                <tr>
                  <td>Username:</td>
                  <td>{{ originalRecord.ownerId }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.fal.file.view_access')}}:</td>
                  <td v-if="originalRecord.viewAccessesRoles">{{ originalRecord.viewAccessesRoles.join(', ')
                    }}
                  </td>
                  <td v-else>-</td>
                </tr>
                <tr>
                  <td>{{__('entity.fal.file.mod_access')}}:</td>
                  <td v-if="originalRecord.modAccessesRoles">{{ originalRecord.modAccessesRoles.join(', ')
                    }}
                  </td>
                </tr>
                <tr>
                  <td>{{__('entity.common.created_at')}}:</td>
                  <td>{{ originalRecord.createdAt }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.common.updated_at')}}:</td>
                  <td>{{ originalRecord.updatedAt }}</td>
                </tr>
              </table>
            </div>
            <div class="flex xs12 sm6">
              <div class="layout layout--wrap">
                <div class="flex xs12 mt">
                  <text-field
                      v-model="record.name"
                      :label="__('entity.common.name')"
                      :error-messages="errors.name"
                      maxlength="50"
                      :prepend-icon="isChanged('name') ? 'pencil' : null"
                      @input="registerChange('name')"
                  />
                </div>
                <div class="flex xs12 mt">
                  <text-area
                      v-model="record.description"
                      :label="__('entity.common.description')"
                      :error-messages="errors.description"
                      :prepend-icon="isChanged('description') ? 'pencil' : null"
                      @input="registerChange('description')"
                  />
                </div>
                <div class="flex xs12">
                  <label>{{__('entity.fal.file.view_access')}}</label>
                  <multi-select
                      v-model="record.viewAccessesRoleIds"
                      :endpoint="'/identity/role'"
                      :prepend-icon="isChanged('viewAccessesRoleIds') ? 'pencil' : ''"
                      @input="registerChange('viewAccessesRoleIds')"
                  />
                </div>
                <div class="flex xs12 mt">
                  <label>{{__('entity.fal.file.mod_access')}}</label>
                  <multi-select
                      v-model="record.modAccessesRoleIds"
                      :endpoint="'/identity/role'"
                      :prepend-icon="isChanged('modAccessesRoleIds') ? 'pencil' : ''"
                      @input="registerChange('modAccessesRoleIds')"
                  />
                </div>
              </div>
              <div class="flex xs6 mt">
                <div class="btn btn--success" :loading="loading" :disabled="changedProperties.length === 0"
                     @click="update" v-slashes>{{ __('common.save_changes') }}
                </div>
              </div>
              <div class="flex xs6 mt">
              </div>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import {localizationMixin} from '../../mixins/localization';
  import {editFormMixin} from "../../mixins/editForm";

  export default {
    mixins: [localizationMixin, editFormMixin],
    data: () => ({
      endpoint: '/fal/file',
      emptyRecord: {
        id: null,
        name: '',
        description: '',
        viewAccesses: [],
        viewAccessesRoleIds: [],
        modAccesses: [],
        modAccessesRoleIds: [],
        createdAt: '---',
        updatedAt: '---'
      },
    }),
    methods: {
      postProcess() {
        this.record.viewAccessesRoleIds = this.record.viewAccesses.map(viewAccess => {
          return viewAccess.roleId;
        });
        this.record.modAccessesRoleIds = this.record.modAccesses.map(modAccess => {
          return modAccess.roleId;
        });
      },
    },
  }
</script>