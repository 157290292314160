<template>
  <div>
    <div class="app-header">
      <div class="app-header__brand">
        <router-link
            tag="div"
            class="app-header__brand-icon"
            :to="{name: 'dashboard'}"
            v-slashes
        >
          <img src="https://cdn.hyperion-corporation.de/ui/svgs/hyperium-logo_1.svg" alt="Hyperion"/>
        </router-link>
        <div class="app-header__title">{{ title }}</div>
      </div>
      <div class="app-header__controls" v-if="$store.state.user">
        <div class="app-header__button" @click="$store.commit('openMainMenu')" v-slashes>
          <img class="app-header__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/hamburger.svg" alt="menu"/>
          <div class="app-header__button-title">{{__('common.menu')}}</div>
        </div>
        <div class="app-header__button" @click="showEditMyself" v-slashes>
          <img class="app-header__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/helmet-mc.svg" alt="helmet"/>
          <div class="app-header__button-title">{{ $store.state.user.username }}</div>
        </div>
        <div class="app-header__button" @click="$store.commit('deleteUser')" v-slashes>
          <img class="app-header__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/door-mc.svg" alt="logout"/>
          <div class="app-header__button-title">{{__('common.logout')}}</div>
        </div>
      </div>
    </div>
    <edit-myself ref="editMyself"/>
  </div>
</template>

<script>
  import { userMixin } from "../mixins/user";
  import EditMyself from '../components/Auth/EditMyself'
  import { notificationsMixin } from '../mixins/notifications';
  import { localizationMixin } from '../mixins/localization';

  export default {
    components: {
      editMyself: EditMyself
    },
    mixins: [userMixin, notificationsMixin, localizationMixin],
    props: ['title', 'hideBack'],
    data: () => ({
      loading: false,
      userToEdit: null
    }),
    methods: {
      showEditMyself() {
        this.$refs.editMyself.show();
      },
      showMainMenu() {
        this.$store.commit('openMainMenu');
      }
    },
  }
</script>
