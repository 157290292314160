<template>
  <div class="swiper">
    <my-dialog :show="show" :fullscreen="true" @close="close">
      <div class="card card--no-background-image">
        <div class="card__header">
          <div class="card__title">{{ currentDir ? currentDir.fullRelativePath : 'root' }}</div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content card__content--no-padding">
          <div class="swiper-container swiper-container--full-height">
            <div class="swiper-wrapper">
              <template v-for="file in onlyMediaFiles()">
                <div
                    class="swiper-slide"
                    :key="file.filename"
                    :style="renderBackgroundImage(file)"
                >
                  <video controls v-if="file.type.toLowerCase() === 'mp4'" class="swiper-slide-video" preload="none"
                         :poster="renderPoster(file)">
                    <source :src="file.uri" type="video/mp4"/>
                  </video>
                  <div class="swiper-slide-info">
                    <h2 v-if="file.name">{{ file.name }}</h2>
                    <h2 v-else>{{ file.filename }}</h2>
                  </div>
                </div>
              </template>
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import { notificationsMixin } from '../../mixins/notifications';// require styles
  import Swiper from 'swiper';
  import 'swiper/swiper.scss'

  export default {
    mixins: [notificationsMixin],
    props: ['files', 'currentDir'],
    data: () => ({
      loading: false,
      show: false,
      mediaTypes: ['jpg', 'png', 'gif', 'svg', 'mp4'],
      swiperOptions: {
        autoHeight: false,
        effect: 'slide',
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          slideChange: function () {
            Array.from(document.getElementsByClassName('swiper-slide-video')).forEach(video => {
              video.pause();
            })
          }
        }
      },
      swiperInstance: null
    }),
    methods: {
      onlyMediaFiles() {
        return this.files.filter(file => this.mediaTypes.includes(file.type.toLowerCase()));
      },
      showDialog(file = null) {
        this.show = true;
        setTimeout(() => {
          this.initSwiper();
          if(file) {
            this.swiperInstance.slideTo(this.onlyMediaFiles().findIndex(ffile => ffile.filename === file.filename));
          }
        }, 100);
      },
      close() {
        Array.from(document.getElementsByClassName('swiper-slide-video')).forEach(video => {
          video.pause();
        });
        this.show = false;
        this.$emit('closed')
      },
      initSwiper() {
        if(this.swiperInstance) {
          this.swiperInstance.destroy();
        }
        this.swiperInstance = new Swiper('.swiper-container', this.swiperOptions);
      },
      renderBackgroundImage(file) {
        if (file.type.toLowerCase() !== 'mp4') {
          return { 'background-image': `url('${file.uri}')` }
        }
        return {};
      },
      renderPoster(file) {
        return process.env.VUE_APP_CDN_PUBLIC_BASE_PATH + file.thumbnail;
      }
    },
    created() {
    }
  }
</script>