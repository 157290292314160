<template>
  <div class="file-upload">
    <my-dialog :show="show" :fullscreen="true" @close="close">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('component.files.upload_files.title')}}</div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content">
          <div class="layout layout--wrap">
            <div class="flex xs12">
              <div class="file-upload__global-actions"
                   :class="{'file-upload__global-actions--disabled': countUploading > 0}">
                <label class="file-upload__select-files">
                  {{__('component.files.upload_files.instruction')}}
                  <input type="file" multiple @change="handleNewFiles"/>
                </label>
                <div class="file-upload__global-option">
                  <my-switch :label="'skip existing files'"
                             v-model="skipExisting"/>
                </div>
                <div class="file-upload__global-button"
                     :title="__('component.files.upload_files.start_upload')"
                     :class="{'file-upload__global-button--disabled': !uploadableFiles, 'pulse': uploadableFiles && !isUploading}" @click="uploadFiles">
                  <img src="https://cdn.hyperion-corporation.de/ui/svgs/upload.svg" alt="x"/>
                </div>
              </div>
            </div>
            <div class="flex xs12">
              <div class="file-upload__files" :key="rerenderKey">
                <p class="text--center">{{__('component.files.upload_files.optimization_explanation')}}</p>
                <div v-for="(file, index) in files"
                     class="flex file-upload__file"
                     :key="'file_' + index"
                >
                  <div class="file-upload__file-progress"
                       :class="fileProgressClass(file)"
                       :style="{ width: file.progress + '%'}"
                  ></div>
                  <div class="layout">
                    <div class="flex flex--v-center xs4">{{ file.name }}</div>
                    <div class="flex flex--v-center xs2">{{ fileSize(file.size) }}</div>
                    <div class="flex flex--v-center xs2">{{ file.type }}</div>
                    <div class="flex flex--v-center xs2">
                      <span v-if="file.state === 'uploading'">
                        <template v-if="file.progress < 100">{{ file.state }} {{ file.progress }}%</template>
                        <template v-else>{{__('component.files.upload_files.optimizing')}}</template>
                      </span>
                      <span v-if="file.state === 'pending'">Click upload to start</span>
                      <span v-else>{{ file.state }}</span>
                    </div>
                    <div class="flex flex--v-center xs2 file-upload__file-actions">
                      <div class="file-upload__file-button"
                           v-if="file.state !== 'uploaded' && file.state !== 'uploading'"
                           @click="removeFile(file)"
                      >
                        <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import filesize from 'filesize';
  import apiSecured from '../../api/secured';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { rerenderMixin } from "../../mixins/rerender";

  export default {
    mixins: [notificationsMixin, rerenderMixin, localizationMixin],
    props: ['currentDir'],
    data: () => ({
      show: false,
      files: [],
      newFiles: [],
      countUploading: 0,
      rerenderKey: 'no-set',
      skipExisting: false,
    }),
    computed: {
      uploadableFiles() {
        return this.files.filter((file) => file.state !== 'uploaded').length > 0;
      },
      isUploading() {
        return this.uploadableFiles && this.countUploading > 0;
      }
    },
    methods: {
      showDialog() {
        this.files = [];
        this.show = true;
      },
      close() {
        this.files = [];
        this.show = false;
        this.$emit('closed')
      },
      fileProgressClass(file) {
        return 'file-upload__file-progress--' + file.state;
      },
      handleNewFiles(event) {
        let files = Array.from(event.target.files);
        files.forEach((file) => {
          if (file.size > 5368709120) {
            this.showErrorNotifications(this.__('component.files.upload_files.file_too_big').replace('$1', file.name));
            return;
          }
          if (!this.files.find((registeredFile) => registeredFile.name === file.name)) {
            this.files.push({
              name: file.name,
              lastModified: file.lastModified,
              lastModifiedDate: file.lastModifiedDate,
              size: file.size,
              type: file.type,
              file: file,
              state: 'pending'
            })
          }
        });
        event.target.value = '';
      },
      uploadFiles() {
        this.files.forEach((file) => {
          if (file.state === 'uploaded') {
            return;
          }
          file.state = 'uploading';
          file.progress = 0;
          this.countUploading++;
          let payload = new FormData();
          payload.append('file', file.file);
          let endpoint = `/fal/file/upload/${this.currentDir.id}?skipExisting=${this.skipExisting}`;
          let event = 'filesUploaded';
          apiSecured.post(endpoint, payload,
            {
              'headers': { 'Content-Type': 'multipart/form-data' },
              onUploadProgress: (progressEvent) => {
                file.progress = Math.round((progressEvent.loaded * 100) / file.size);
                this.forceRerender();
              }
            }
          ).then((res) => {
            this.countUploading--;
            file.name = res.data.filename;
            file.state = 'uploaded';
            file.progress = 100;
            if (this.countUploading === 0) {
              this.$emit(event);
            }
            this.forceRerender()
          })
            .catch((error) => {
              this.countUploading--;
              if(error.response.status === 409) {
                file.state = 'conflict';
              } else {
                file.state = 'error';
              }
              file.progress = 100;
              if (this.countUploading === 0) {
                this.$emit(event);
              }
              if(error.response.status === 409) {
                this.showWarningNotification(`file ${file.name} exists already`);
              } else {
                this.showErrorNotifications(error);
              }
              this.forceRerender()
            });
        })
      },
      fileSize(bytes) {
        return filesize(bytes);
      },
      removeFile(file) {
        this.files.splice(this.files.indexOf(file), 1);
      }
    }
  }
</script>

<style scoped>

</style>