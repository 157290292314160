<template>
  <div class="edit-myself">
    <my-dialog :show="showMe" @close="close">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{ __('component.auth.edit_myself.title') }}</div>
          <div class="card__button" v-slashes @click="close"><img
              src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/></div>
        </div>
        <div class="card__content" v-if="originalUser">
          <progress-linear v-if="loading"/>
          <div class="layout layout--wrap">
            <div class="flex xs12 sm6">
              <table class="object-table">
                <tr>
                  <td>{{__('entity.common.id')}}:</td>
                  <td>{{ originalUser.id}}</td>
                </tr>
                <tr>
                  <td>{{__('entity.auth.user.username')}}:</td>
                  <td>{{ originalUser.userName }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.auth.user.email')}}:</td>
                  <td>{{ originalUser.email }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.auth.user.emailConfirmed')}}:</td>
                  <td>{{ originalUser.emailConfirmed }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.auth.user.enabled')}}:</td>
                  <td>{{ !originalUser.lockoutEnabled }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.auth.user.roles')}}:</td>
                  <td>{{ Array.from(originalUser.roleNames).join(', ') }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.auth.user.last_login')}}:</td>
                  <td>{{ originalUser.lastLogin }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.common.created_at')}}:</td>
                  <td>{{ originalUser.createdAt }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.common.updated_at')}}:</td>
                  <td>{{ originalUser.updatedAt }}</td>
                </tr>
                <tr>
                  <td>Discord Id:</td>
                  <td>{{ originalUser.discordId }}</td>
                </tr>
                <tr>
                  <td>Discord Login:</td>
                  <td>
                    <span v-if="!originalUser.discordId" class="ribbon error">not connected</span>
                    <span v-else class="ribbon success">enabled</span>
                  </td>
                </tr>
                <tr v-if="!originalUser.discordId">
                  <td>Connect to Discord:</td>
                  <td>
                    <div @click="authorizeDiscord" class="btn btn--success mt" v-slashes>Authorize Discord</div>
                  </td>
                </tr>
                <tr v-if="hasOneRoles(['Member'])">
                  <td>{{__('component.auth.edit_myself.dll_authentification')}}:</td>
                  <td>
                    <text-area
                        :id="'auth_code'"
                        class="text--code"
                        v-model="externalAuthentificationCode"
                        :readonly="true"
                        :hide-details="true"
                        :label="__('component.auth.edit_myself.copy_this_code')"
                    />
                    <div @click="copyToClipBoard" class="btn btn-primary mt" v-slashes>
                      {{__('component.auth.edit_myself.copy_to_clipboard')}}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>{{__('component.auth.edit_myself.preferredLanguage')}}</td>
                  <td>
                    <single-select
                        :items="languages"
                        v-model="preferredLanguage"
                        @input="savePreverredLanguage('state')"
                    />
                  </td>
                </tr>
              </table>
            </div>
            <div class="flex xs12 sm6 mt">
              <text-field
                  v-model="user.avatar"
                  :label="__('component.auth.edit_myself.avatar_url')"
                  :error-messages="errors.avatar"
                  maxlength="255"
                  :prepend-icon="isChanged('avatar') ? 'pencil' : null"
                  @input="registerChange('avatar')"
              />
              <select-avatar
                      v-if="hasOneRoles(['Member'])"
                      class="mt mr mb"
                      :original-avatar="user.avatar"
                      ref="selectAvatar"
                      :changed="changedProperties.includes('avatar')"
                      @avatarSelected="handleAvatarSelected"
              />
            </div>
            <div class="flex xs6 mt">
            </div>
            <div class="flex xs6 mt">
              <div class="btn btn--success" :loading="loading" :disabled="changedProperties.length === 0"
                   @click="updateUser" v-slashes>{{ __('common.save_changes') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import deepcopy from 'deepcopy';
  import apiSecured from '../../api/secured';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import SelectAvatar from './SelectAvatar';
  import {userMixin} from "../../mixins/user";

  export default {
    components: {
      SelectAvatar
    },
    mixins: [notificationsMixin, localizationMixin, userMixin],
    data: () => ({
      loading: false,
      originalUser: null,
      user: null,
      showMe: false,
      changedProperties: [],
      errors: {
        avatar: []
      },
      externalAuthentificationCode: '',
      languages: [
        { value: 'en', title: 'english' },
        { value: 'de', title: 'deutsch' },
      ],
      preferredLanguage: null
    }),
    methods: {
      authorizeDiscord() {
        this.$router.push({name: 'discord-redirect-connect'});
      },
      savePreverredLanguage() {
        localStorage.setItem('preferredLanguage', this.preferredLanguage);
      },
      show() {
        this.getUserData();
        this.showMe = true;
      },
      prepare() {
        this.user = deepcopy(this.originalUser);
      },
      getUserData() {
        this.loading = true;
        this.changedProperties = [];
        apiSecured.get('/identity/Myself').then((res) => {
          this.originalUser = res.data;
          this.loading = false;
          this.showInfoNotification(this.__('component.auth.edit_myself.userdata_loaded'));
          this.changedProperties = [];
          this.prepare();
          this.externalAuthentificationCode = `
"accessToken": "${this.getAccessToken()}",
"refreshToken":"${this.getRefreshToken()}"
`;
        }).catch((error) => {
          this.originalUser = null;
          this.user = null;
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      registerChange(property) {
        if (!this.changedProperties.includes(property)) {
          this.changedProperties.push(property);
        }
      },
      isChanged(property) {
        return this.changedProperties.includes(property);
      },
      close() {
        this.showMe = false;
      },
      handleAvatarSelected(avatar) {
        this.user.avatar = avatar;
        this.registerChange('avatar');
      },
      updateUser() {
        let payload = [];
        let isValid = true;
        this.errors = {
          avatar: []
        };
        this.changedProperties.forEach((changedProperty) => {
            payload.push({
              op: 'replace',
              path: `/${changedProperty}`,
              value: this.user[changedProperty]
            });
        });

        if (!isValid) {
          return;
        }

        this.loading = true;
        apiSecured.patch('/identity/Myself', payload).then((res) => {
          this.loading = false;
          this.showSuccessNotification(this.__('common.saved'));
          this.originalUser = res.data;
          this.prepare();
          this.changedProperties = [];
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
      getAccessToken() {
        return localStorage.getItem('accessToken');
      },
      getRefreshToken() {
        return localStorage.getItem('refreshToken');
      },
      copyToClipBoard() {
        let copyText = document.getElementById("auth_code");
        copyText.select();
        document.execCommand("copy");

        this.showSuccessNotification(this.__('component.auth.edit_myself.copied_to_clipboard'));
      }
    },
    created() {
      this.preferredLanguage = localStorage.getItem('preferredLanguage');
    }
  }
</script>
